header .main-bar__logo {
  grid-column: 1/span 2;
}
header .main-bar__search {
  grid-column-start: 3;
}
@media (min-width: 1024px) {
  header .main-bar__search {
    grid-column-start: 4;
  }
}
header .main-bar__languagemenu {
  grid-column: 3;
  justify-self: end;
}
@media (min-width: 768px) {
  header .main-bar__languagemenu {
    grid-column: 6/span 2;
    justify-self: start;
  }
}
@media (min-width: 1024px) {
  header .main-bar__languagemenu {
    grid-column: 7/span 2;
  }
}
header .main-bar__languagemenu .main-header__language-container {
  position: relative;
}
header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255, 255, 255);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
@supports (color: rgba(0, 0, 0, 0)) {
  header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger {
    border-color: rgb(var(--secondary-rgb)/var(--tw-border-opacity));
  }
}
header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown] {
  --tw-text-opacity: 1;
  width: auto;
}
@media screen and (min-width: 768px) {
  header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown] {
    width: 100%;
  }
}
header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown] > svg {
  height: 1rem;
  width: 1rem;
  fill: currentColor;
  color: rgb(var(--secondary-rgb)/var(--tw-text-opacity));
}
header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown] > svg:last-of-type {
  margin-left: auto;
}
header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown] > span {
  display: none;
  color: rgb(var(--secondary-rgb)/var(--tw-text-opacity));
}
@media screen and (min-width: 768px) {
  header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown] > span {
    display: block;
  }
}
header .main-bar__languagemenu .main-header__language-container .main-header__language-trigger[data-target*=dropdown][aria-expanded=true] > svg:first-of-type {
  display: block;
}
header .main-bar__languagemenu .main-header__language-container .main-header__language {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--secondary-rgb)/var(--tw-bg-opacity));
  z-index: 1000;
  width: auto;
}
@media screen and (min-width: 768px) {
  header .main-bar__languagemenu .main-header__language-container .main-header__language {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  header .main-bar__links-desktop,
  header .main-bar > *:last-child {
    grid-column-end: 13;
    grid-column-start: span 4;
  }
}
footer .main-footer__language-container {
  display: none;
}
footer .mini-footer__emvi,
footer .footer_mitgestalten {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px;
  padding-bottom: 1rem;
  color: white;
}
@media (min-width: 640px) {
  footer .mini-footer__emvi,
  footer .footer_mitgestalten {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  footer .mini-footer__emvi,
  footer .footer_mitgestalten {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  footer .mini-footer__emvi,
  footer .footer_mitgestalten {
    max-width: 1024px;
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
footer .mini-footer__emvi a span,
footer .footer_mitgestalten a span {
  display: none;
}
footer .mini-footer__emvi {
  flex-wrap: wrap;
}
footer .mini-footer__emvi span,
footer .mini-footer__emvi a {
  flex: 0 1 40%;
}
footer .mini-footer__emvi span img,
footer .mini-footer__emvi a img {
  max-width: 150px;
  margin-bottom: 16px;
}
footer .mini-footer__emvi span img.eu,
footer .mini-footer__emvi a img.eu {
  max-width: 250px;
}
footer .mini-footer__emvi a {
  display: flex;
  justify-content: center;
}
footer .footer_mitgestalten {
  flex-direction: column;
  gap: 0;
}
footer .footer_mitgestalten img {
  max-width: 150px;
}